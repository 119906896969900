import { ReactNode } from 'react';
import cn from 'classnames';
import Rating from 'src/core/common/components/common/Rating';
import Icon from 'src/core/svg-sprites/ui/atoms/Icon';
import ExpertReviewCardAvatar, {
  ExpertReviewCardAvatarProps,
} from '../../atoms/ExpertReviewCardAvatar';
import styles from './ExpertReviewCard.module.scss';

export type ExpertReviewCardProps = {
  avatarProps?: Omit<ExpertReviewCardAvatarProps, 'image' | 'name'>;
  name: string;
  rating: number;
  text?: string;
  src?: string;
  image?: ReactNode;
  className?: string;
  ratingSize?: 'small' | 'medium' | 'large';
};

const ExpertReviewCard = ({
  name,
  rating,
  text = '',
  image,
  src,
  className,
  ratingSize = 'medium',
  avatarProps = {},
}: ExpertReviewCardProps) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.infoWrapper}>
        {!!image ? image : <ExpertReviewCardAvatar {...avatarProps} name={name} image={src} />}
        <div className={styles.reviewInfo}>
          <span className={styles.name}>{name}</span>
        </div>
        <Rating
          value={rating}
          className={styles.rating}
          precision={0.2}
          readOnly
          size={ratingSize}
        />
      </div>
      <div className={styles.quoteWrapper}>
        <div className={styles.quoteContainer}>
          <Icon
            name="quotation-marks"
            fill="url(#gradient-pink-base)"
            className={styles.quotationMarks}
          />
          <p className={styles.quoteDarkText}>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default ExpertReviewCard;
