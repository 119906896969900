import cn from 'classnames';
import { getNameTwoLetters } from 'src/core/common/utils/userName';
import Image from 'src/core/common/components/common/Image';
import styles from './ExpertReviewCardAvatar.module.scss';

export type ExpertReviewCardAvatarProps = {
  name: string;
  image?: string;
  className?: string;
};

const ExpertReviewCardAvatar = ({ name, image, className = '' }: ExpertReviewCardAvatarProps) => {
  if (image) {
    return (
      <Image
        className={cn(styles.imageAvatar, className)}
        src={image}
        alt="avatar"
        height={45}
        width={45}
      />
    );
  }
  return <div className={cn(styles.avatar, className)}>{getNameTwoLetters(name)}</div>;
};

export default ExpertReviewCardAvatar;
