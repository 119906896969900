/* eslint-disable @typescript-eslint/no-unused-vars */
// Need to disallow passing currentSlide and slideCount to DOM nodes, which triggers warning /

import cn from 'classnames';
import { ReactNode } from 'react';
import IconButton from 'src/core/common/components/common/IconButton';

const CarouselButton = ({
  className,
  classNames = '',
  children,
  slideCount,
  currentSlide,
  ...otherProps
}: {
  className?: string;
  onClick?: () => void;
  classNames?: string;
  slideCount?: number;
  currentSlide?: number;
  children?: ReactNode;
}) => (
  <IconButton {...otherProps} className={cn(classNames, className)}>
    {children}
  </IconButton>
);

export default CarouselButton;
