import cn from 'classnames';
import Rating from 'src/core/common/components/common/Rating';
import { ReviewSection } from 'src/core/contentManager/entities';

import ExpertReviewCard from '../../molecules/ExpertReviewCard';
import styles from './SectionReviews.module.scss';

export type SectionReviewsProps = {
  sectionContent: ReviewSection;
  className?: string;
};

const SectionReviews = ({ sectionContent, className }: SectionReviewsProps) => {
  const { title, reviews, description } = sectionContent;
  return (
    <section className={cn(styles.root, className)} id={sectionContent.component}>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.rating}>
          {description}
          <Rating value={5} className={styles.stars} precision={0.2} readOnly size="medium" />
        </p>
        <ul className={styles.reviewList}>
          {reviews.map(({ text, customerName, rating, id }) => (
            <li key={id} className={styles.reviewListItem}>
              <ExpertReviewCard
                className={styles.review}
                avatarProps={{ className: styles.reviewAvatar }}
                ratingSize="small"
                text={text}
                name={customerName}
                rating={rating}
              />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default SectionReviews;
