import { ButtonHTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './IconButton.module.scss';

export type IconButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const IconButton = ({ className, children, ...otherProps }: IconButtonProps) => (
  <button type="button" {...otherProps} className={cn(styles.root, className)}>
    <span className={styles.label}>{children}</span>
  </button>
);

export default IconButton;
