import cn from 'classnames';
import { StatsSection } from 'src/core/contentManager/entities';
import quotationMarks from 'public/images/gradient-quotation-marks.png';
import Image from 'src/core/common/components/common/Image';
import styles from './SectionStats.module.scss';

type SectionStatsProps = {
  className?: string;
  sectionContent: StatsSection;
};

const SectionStats = ({ className, sectionContent }: SectionStatsProps) => {
  return (
    <section className={cn(styles.root, className)}>
      <div className={styles.content}>
        <div className={styles.quoteWrapper}>
          <Image
            type="future"
            src={quotationMarks}
            className={cn(styles.quotationMarks, styles.openingQuotationMarks)}
            alt="Opening quotation marks"
          />
          <blockquote className={styles.quoteText}>{sectionContent.quote}</blockquote>
          <Image
            type="future"
            src={quotationMarks}
            className={cn(styles.quotationMarks, styles.closingQuotationMarks)}
            alt="Closing quotation marks"
          />
        </div>
        <ul className={styles.numbersList}>
          {sectionContent.stats.map(({ id, amount, units, object }) => (
            <li key={id} className={styles.numbersItem}>
              <p className={styles.numbersItemInner}>
                <span className={styles.numbersAmount}>{amount}</span>{' '}
                <span className={styles.numbersUnits}>{units}</span> <br />
                <span className={styles.numbersSubject}>{object}</span>
              </p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default SectionStats;
